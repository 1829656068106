// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-js": () => import("/opt/build/repo/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-project-js": () => import("/opt/build/repo/src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-book-js": () => import("/opt/build/repo/src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("/opt/build/repo/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-books-js": () => import("/opt/build/repo/src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resume-js": () => import("/opt/build/repo/src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

